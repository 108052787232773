<template>
  <v-container class="background fill-height d-block" fluid>
    <!--  Navigation  -->
    <u-project-top-nav :title="activeProjectName" extended>
      <template #extension>
        <u-stepper-progression-variant
          :step="stepper.step"
          :sub-step="stepper.subSteps"
          :subtitle="stepper.subtitle"
          :title="stepper.title"
          @click:prev="$router.push(stepper.prevLink)"
          @click:next="$router.push(stepper.nextLink)"
        />
      </template>
    </u-project-top-nav>

    <!--  Breadcrumbs  -->
    <v-container class="pb-0" fluid>
      <u-breadcrumbs :items="breadcrumbItems" />
      <h1 class="secondary--text">Video Setup</h1>
    </v-container>

    <v-row class="content ma-2">
      <!--  Video List by Camera  -->
      <video-setup-camera-container
        v-model="selectedVideo"
        class="col ma-1 fill-height"
        @click:addVideos="showCameraSelectorModal = true"
      />

      <!--  Video Details  -->
      <video-setup-video-details
        v-if="selectedVideo"
        :video-details="selectedVideo"
        class="col col-4 ma-1"
        @delete-video="showDeleteVideoModal = true"
        @click:newCamera="showNewCameraModal = true"
      />
    </v-row>

    <!--  Action Button  -->
    <v-row class="px-4 my-1" justify="end">
      <u-button
        class="text-capitalize font-weight-bold secondary--text"
        color="primary"
        large
        @click="triggerUploadConfirmation"
      >
        Start Upload
      </u-button>
    </v-row>

    <u-confirmation-modal
      v-model="showDeleteVideoModal"
      :body="`Delete file ${deleteFileName}? This change cannot be undone`"
      action-name="delete"
      title="Are you sure?"
      @click:confirmAction="deleteSelectedVideo"
    />

    <add-videos v-model="showCameraSelectorModal" />

    <!--  New Camera Modal  -->
    <camera-details-modal
      v-if="showNewCameraModal"
      @click:apply="showNewCameraModal = false"
      @click:close="showNewCameraModal = false"
    />

    <upload-confirmation-modal
      v-model="showUploadConfirmationModal"
      @click:startUpload="uploadVideos"
    />

    <video-setup-error-modal
      v-model="showVideoSetupErrorModal"
      :videos-missing-date="videosMissingDate"
      :videos-missing-time="videosMissingTime"
    />
  </v-container>
</template>

<script>
import { UBreadcrumbs, UButton } from "@/components/base";
import {
  UProjectTopNav,
  UStepperProgressionVariant,
} from "@/components/common";
import {
  VideoSetupVideoDetails,
  VideoSetupCameraContainer,
  AddVideos,
} from "@/components";
import {
  CameraDetailsModal,
  UploadConfirmationModal,
  UConfirmationModal,
  VideoSetupErrorModal,
} from "@/components/modals";

import { mapActions, mapGetters, mapState } from "vuex";
import { PROJECT_SETUP_STEPPER } from "@/utils/navStepper.data";
import { PROJECT_SETUP_BREADCRUMBS } from "@/utils/breadcrumbs.data";

export default {
  name: "VideoSetup",
  components: {
    VideoSetupErrorModal,
    CameraDetailsModal,
    AddVideos,
    UploadConfirmationModal,
    UConfirmationModal,
    VideoSetupCameraContainer,
    UStepperProgressionVariant,
    VideoSetupVideoDetails,
    UProjectTopNav,
    UBreadcrumbs,
    UButton,
  },
  mounted() {
    !this.projectCameras.length && this.getProjectCameras(this.activeProjectId);
  },
  data() {
    return {
      // Header
      stepper: PROJECT_SETUP_STEPPER.videoSetup,
      breadcrumbItems: PROJECT_SETUP_BREADCRUMBS,

      selectedVideo: undefined,

      // Modals
      showUploadConfirmationModal: false,
      showDeleteVideoModal: false,
      showNewCameraModal: false,
      showCameraSelectorModal: false,
      showVideoSetupErrorModal: false,
    };
  },
  computed: {
    ...mapState("Project", ["projectCameras"]),
    ...mapGetters("Project", ["activeProjectName", "activeProjectId"]),
    ...mapGetters("VideoUpload", [
      "videosMissingDate",
      "videosMissingTime",
      "videosMissingDetails",
    ]),
    validateFilesToUpload: {
      get() {
        return this.$store.state.VideoUpload.validateFilesToUpload;
      },
      set(value) {
        this.$store.state.VideoUpload.validateFilesToUpload = value;
      },
    },

    deleteFileName() {
      return this.selectedVideo?.filename ?? "";
    },
  },
  methods: {
    ...mapActions("Project", ["getProjectCameras"]),
    // Upload all selected videos
    uploadVideos() {
      this.$store.dispatch("VideoUpload/uploadVideos");

      this.$router.push({
        name: "Video Upload",
        params: { project_id: this.activeProjectId },
      });
    },

    // Show Upload Confirmation Modal
    triggerUploadConfirmation() {
      this.validateFilesToUpload = true;
      this.videosMissingDetails.length !== 0
        ? (this.showVideoSetupErrorModal = true)
        : (this.showUploadConfirmationModal = true);
    },

    deleteSelectedVideo() {
      this.$store.commit("VideoUpload/removeFile", this.selectedVideo);
      this.showDeleteVideoModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-border {
  border: 2px solid var(--v-gray-7-base);
}
</style>
