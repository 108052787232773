const PROJECT_SETUP_STEPPER = {
  createProject: {
    title: "Fill in the details",
    subtitle: "Fill in all the necessary details to help setup your project",
    step: "1",
    subSteps: "1.1-1.6",
    nextLink: { name: "Video Library" },
    prevLink: { name: "Dashboard" },
  },
  videoLibraryPlaceholder: {
    title: "Setup cameras by uploading relevant footage",
    subtitle:
      "Select and upload footage required for your workflow. Specify camera name and location and create a new camera",
    step: "1.2",
    subSteps: "2/6",
    nextLink: { name: "Video Setup" },
    prevLink: { name: "Create Project" },
  },
  videoLibrary: {
    title: "Uploaded videos are listed here",
    subtitle:
      "Get an overview of all uploaded videos, cameras and their metadata here and track your project’s progress.",
    step: "1.6",
    subSteps: "6/6",
    prevLink: { name: "Video Setup" },
    nextLink: { name: "Camera Configuration" },
  },
  videoSetup: {
    title: "Setup Cameras",
    subtitle:
      "Organize footage based on cameras used and setup cameras with their details",
    step: "1.3",
    subSteps: "3/6",
    nextLink: { name: "Video Upload" },
    prevLink: { name: "Video Library" },
  },
  videoUpload: {
    title: "Check upload progress here",
    subtitle:
      "Find the status of all ongoing uploads here and manage them accordingly.",
    step: "1.5",
    subSteps: "5/6",
    nextLink: { name: "Video Library" },
    prevLink: { name: "Video Setup" },
  },
};

const CONFIG_STEPPER = {
  cameraConfig: {
    title: "Setup Detection",
    subtitle:
      "Mark detection areas by adjusting the points on the footage and map preview",
    step: "2",
    subSteps: "2.1-2.2",
    nextLink: { name: "Metric Configuration" },
    prevLink: { name: "Video Library" },
  },
  metricConfig: {
    title: "Setup Metrics",
    subtitle: "Mark areas needed for metrics and configure parameters",
    step: "3",
    subSteps: "3.1-3.4",
    nextLink: { name: "Dashboard" },
    prevLink: { name: "Camera Configuration" },
  },
};

export { PROJECT_SETUP_STEPPER, CONFIG_STEPPER };
