const DASHBOARD = {
  text: "Dashboard",
  href: "/",
};

const PROJECT_SETUP_BREADCRUMBS = [
  DASHBOARD,
  {
    text: "Project Setup",
    href: "/setup",
  },
];

const PROJECT_DETAILS_BREADCRUMBS = [
  DASHBOARD,
  {
    text: "Project Details",
    href: "/setup",
  },
];

const CAMERA_CONFIGURATION_BREADCRUMBS = [
  DASHBOARD,
  {
    text: "Config",
    href: "/config",
  },
  {
    text: "Camera Configuration",
    href: "/config",
  },
];

const METRIC_CONFIGURATION_BREADCRUMBS = [
  DASHBOARD,
  {
    text: "Metrics",
    href: "/metrics",
  },
  {
    text: "Metric Configuration",
    href: "/metrics",
  },
];

export {
  PROJECT_SETUP_BREADCRUMBS,
  PROJECT_DETAILS_BREADCRUMBS,
  CAMERA_CONFIGURATION_BREADCRUMBS,
  METRIC_CONFIGURATION_BREADCRUMBS,
};
